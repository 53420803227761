(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
jQuery(document).ready(function () {
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop()) {
            jQuery('#toTop').fadeIn();
        } else {
            jQuery('#toTop').fadeOut();
        }
    });

    jQuery("#toTop").click(function () {
        jQuery("html, body").animate({ scrollTop: 0 }, 300);
    });

    jQuery(window).scroll(function () {
        if (jQuery(window).scrollTop() >= 100) {
            jQuery('nav').addClass('fixed-header');
            jQuery('header').css("top", "-98");
        }
        else {
            jQuery('nav').removeClass('fixed-header');
            jQuery('header').css("top", "0");
        }
    });
    jQuery(function ($) {
        var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
        $('.navbar ul a').each(function () {
            if (this.href === path) {
                $(this).addClass('active');
            }
        });
    });

    AOS.init();
});


},{}]},{},[1])